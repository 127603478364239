import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/categoryenhancer";
import NavigationActions from "redux/navigation/actions";
import { useParams, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import Select from "react-select";
import {
  addCategory,
  getCategories,
  getParentCategories,
  updateCategory
} from "services/categoryServices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const CategoriesAddForm = props => {
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    resetForm,
    setFieldValue,
    values,
    handleChange,
    handleSubmit,
    setValues,
    isValid,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const { id } = useParams();

  //USESTATE

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryImage, setCategoryImage] = useState();
  const [img, setImg] = useState({ category_Image: "" });

  const get_ParentCategories = async () => {
    await getParentCategories(token).then(data => {
      if (data.success) {
        setCategoryOptions(
          data.data.map(val => ({ value: val.id, label: val.name }))
        );
      } else {
        error(data.message);
      }
    });
  };

  const get_Categoriesby_id = async () => {
    await getCategories(token).then(data => {
      if (data.success) {
        setValues(data.data.find(val => parseInt(val.id) === parseInt(id)));
      } else {
        error(data.message);
      }
    });
  };

  //IMAGE CHANGE

  const onCategoryImageChange = e => {
    const [file] = e.target.files;
    if (file) {
      setImg({ ...img, category_Image: URL.createObjectURL(file) });
    }
  };

  const handleCategorySubmit = async e => {
    e.preventDefault();
    handleSubmit();

    if (isValid) {
      var formData = new FormData();
      for (const val in values) {
        if (val === "category_image") {
          formData.append(val, categoryImage ? categoryImage : values[val]);
        } else {
          formData.append(val, values[val]);
        }
      }
      fetching();
      id
        ? updateCategory(token, formData).then(data => {
            if (data.success) {
              success(data.message);
              // props.history.push("/categories");
            } else {
              error(data.message);
            }
          })
        : addCategory(token, formData).then(data => {
            if (data.success) {
              success(data.message);
              window.location.reload();
              // resetForm()
              // props.history.push("/categories");
            } else {
              error(data.message);
            }
          });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  //USEEFFECTS

  useEffect(() => {
    get_ParentCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    id && get_Categoriesby_id();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {/* <div className="card m-2 p-2"> */}
      <div className="" style={{ margin: "0 8px" }}>
        <div className="mx-3 mt-10">
          <div className="row">
            <div className="col-12 mb-18" style={{ marginLeft: "-8px" }}>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`${
                id ? "Edit" : "Add"
              } Categories`}</span>
            </div>
          </div>
        </div>

        {/* ADD PRODUCT */}
        <div className="card p-2">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                {(values.parent_id === 0
                  ? categoryOptions.filter(x => x.value !== values.id)
                  : categoryOptions
                ).length > 0 ? (
                  <div className="form-group">
                    <label>Parent Category</label>
                    <Select
                      id="parent_id"
                      value={categoryOptions.find(
                        x => x.value === values.parent_id
                      )}
                      placeholder="Select Parent Category"
                      onChange={e => {
                        setFieldValue("parent_id", e.value);
                      }}
                      options={
                        values.parent_id === 0
                          ? categoryOptions.filter(x => x.value !== values.id)
                          : categoryOptions
                      }
                    />
                    <Error field="parentCategory" />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>
                    Category Name <span className="error-msg">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    placeholder="Category Name"
                    id="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  <Error field="name" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>
                    Title <span className="error-msg">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    placeholder="Title"
                    id="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                  />
                  <Error field="title" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>
                    Meta description<span className="error-msg">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    placeholder="Meta Description"
                    id="metaDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.metaDescription}
                  />
                  <Error field="metaDescription" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Key</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    placeholder="Key"
                    id="seoKey"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.seoKey}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Canonical</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    placeholder="Canonical"
                    id="canonical"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.canonical}
                  />
                </div>
              </div>

              <div className="col-12">
                <label>
                  Description <span className="error-msg">*</span>
                </label>
                <div className="">
                  <ReactQuill
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() <= 1) {
                        setFieldValue("description", "  ");
                      } else {
                        setFieldValue("description", content);
                      }
                    }}
                    value={values.description}
                    theme={"snow"}
                    placeholder={"Enter Description... "}
                    modules={CategoriesAddForm.modules}
                    formats={CategoriesAddForm.formats}
                    bounds={".app"}
                    id="description"
                    name="description"
                  />

                  <Error field="description" />
                </div>
              </div>

              {values?.parent_id === 2 ? (
                <div className="col-md-6 mt-15 mb-15">
                  <div>
                    <label>
                      Category Image
                      {/*   <span className="error-msg"> *</span> */}
                    </label>
                  </div>
                  <input
                    type="file"
                    className="mr-2"
                    id="category_image"
                    accept="image/png, image/gif, image/jpeg"
                    onBlur={handleBlur}
                    onChange={e => {
                      if (e.target.files[0]) {
                        onCategoryImageChange(e);
                        setCategoryImage(e.target.files[0]);
                        setFieldValue(
                          "category_image",
                          e.target.files[0]?.name
                        );
                      } else {
                        setCategoryImage();
                        setFieldValue("category_image", "");
                      }
                    }}
                  />
                  {categoryImage ? (
                    <>
                      <a
                        href={img.category_Image}
                        alt={"category_image"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={
                          img.category_Image
                            ? "d-block thumbnail_img"
                            : "d-none"
                        }
                      >
                        <img src={img.category_Image} alt="category-img" />
                      </a>
                    </>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.category_image}`}
                      alt={"category_image"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        values?.category_image
                          ? "d-block thumbnail_img"
                          : "d-none"
                      }
                    >
                      <img
                        src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.category_image}`}
                        alt="category-img"
                      />
                    </a>
                  )}

                  <div>
                    {/* <div>

              <a href = {values?.product_image}
                alt={"product_image"}
                target="_blank"
                rel="noopener noreferrer">
                <img src ={values?.product_image} alt={values?.product_image}/>

              </a>
                    </div> */}
                    <Error field="category_image" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              <div className="col-12 text-center mt-3">
                <Button
                  className="btn c-primary px-5 add_product_design"
                  onClick={e => handleCategorySubmit(e)}
                  type="button"
                  disabled={isFetching}
                >
                  {id ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CategoriesAddForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
CategoriesAddForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(CategoriesAddForm);
