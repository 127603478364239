import React, { useEffect, useState } from "react";
import EditorComponent from "./EditorJsComponent";
import enhancer from "./enhancer/newsenhancer";
import NavigationActions from "redux/navigation/actions";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addNews, updateNews, getNewsByID } from "services/newsServices";
import DisplayEditorContent from "./DisplayEditorContent";
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const NewsAddModal = props => {
  console.log("props.content", props.content);
  console.log("values", values);
  console.log("errors", errors);
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    setFieldValue,
    values,
    setValues,
    handleChange,
    handleSubmit,
    isValid,
    editData,
    handleBlur,
    errors,
    touched,
    submitCount,
    toggleRefresh
  } = props;
  console.log("values", values);

  const { id } = useParams();
  const [img, setImg] = useState();
  const [editorData, setEditorData] = useState([]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  console.log("editor Data", editorData);
  const handleNewsSubmit = async e => {
    e.preventDefault();
    handleSubmit();

    var formData = new FormData();

    setValues({
      ...values,
      news_description: editorData
    });
    let stringifiedDesc = JSON.stringify(editorData);
    formData.append("news", values.news);

    formData.append("news_description", stringifiedDesc);
    formData.append("imageTitle", values.imageTitle);
    formData.append("metaDiscription", values.metaDiscription);
    formData.append("imgAlt", values.imgAlt);
    formData.append("news_image", values.news_image);
    if (id) {
      formData.append("id", id);
    }
    for (const [key, value] of formData) {
      console.log(`${key}: ${value}`, "cjcjjcjcjcjcj");
    }

    if (isValid) {
      fetching();
      id
        ? updateNews(token, formData, id).then(data => {
            if (data.success) {
              success(data.message);
              props.history.push("/news");
            } else {
              error(data.message);
            }
          })
        : addNews(token, formData).then(data => {
            if (data.success) {
              success(data.message);
              props.history.push("/news");
            } else {
              error(data.message);
            }
          });
    }
  };

  const getNews = async () => {
    await getNewsByID(token, id).then(data => {
      if (data.success) {
        setValues(data.data);
        setEditorData(data.data.news_description);
      } else {
        error(data.message);
      }
    });
  };
  //USEEFFECTS

  useEffect(() => {
    id && getNews();
    // eslint-disable-next-line
  }, []);
  console.log("values", values);

  return (
    <>
      <div className="" style={{ margin: "0 8px" }}>
        <div className="mx-3 mt-10">
          <div className="row">
            <div className="col-12 mb-18" style={{ marginLeft: "-8px" }}>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>{`${
                id ? "Edit" : "Add"
              } News`}</span>
            </div>
          </div>
        </div>
        <div className="card p-2">
          <div className="card-body">
            <div className="form-group">
              <label>
                News Title <span className="error-msg">*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                placeholder="Enter The News Name"
                id="news"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.news}
              />
              <Error field="news" />
            </div>
            <div className="form-group">
              <label>
                Meta Discription <span className="error-msg">*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                placeholder="Enter Meta Discription"
                id="metaDiscription"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.metaDiscription}
              />
              <Error field="metaDiscription" />
            </div>
            <div className="react-editor-quill-news">
              <label className="d-block my-2 ">
                Description <span className="error-msg">*</span>
              </label>
              {/* {id ? (
                <div className="editor-display">
                  <DisplayEditorContent
                    editorData={values.news_description.blocks}
                  />
                </div>
              ) : (
                <EditorComponent
                  changeEditorData={(data) => {
                    setEditorData(data);
                  }}
                />
              )} */}
              <EditorComponent
                changeEditorData={data => {
                  setEditorData(data);
                }}
                isAdd={true}
                id={id}
                editorDefaultData={values?.news_description}
              />

              <div>
                <Error field="news_description" />
              </div>
            </div>

            <div className="mb-10">
              <label className="d-block my-2">
                News Feed Image
                <span className="error-msg"> *</span>
              </label>

              <input
                type="file"
                className="mr-2 mb-10"
                id="news_image"
                accept="image/png, image/gif, image/jpeg"
                onBlur={handleBlur}
                onChange={e => {
                  if (e.target.files[0]) {
                    const [file] = e.target.files;
                    if (file) {
                      setImg(URL.createObjectURL(file));
                    }
                    setFieldValue("news_image", e.target.files[0]);
                  } else {
                    setImg();
                    setFieldValue("news_image", "");
                  }
                }}
              />
            </div>
            <Error field="news_image" />

            <div>
              {img ? (
                <>
                  <a
                    href={img}
                    alt={"News_Feed_Image"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={img ? "d-block thumbnail_img" : "d-none"}
                  >
                    <img src={img} alt="product-img" />
                  </a>
                </>
              ) : (
                <a
                  href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.news_image}`}
                  alt={"News_Feed_Image"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    values?.news_image ? "d-block thumbnail_img" : "d-none"
                  }
                >
                  <img
                    src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values?.news_image}`}
                    alt="product-img"
                  />
                </a>
              )}
            </div>

            <div className="form-group">
              <label>
                Img Alt <span className="error-msg">*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                placeholder="Enter Meta Discription"
                id="imgAlt"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.imgAlt}
              />
              <Error field="imgAlt" />
            </div>
            <div className="form-group">
              <label>
                Image Title <span className="error-msg">*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                placeholder="Enter The image title"
                id="imageTitle"
                onBlur={handleBlur}
                value={values.imageTitle}
                onChange={handleChange}
              />
              <Error field="imageTitle" />
            </div>
            <Button
              className="btn c-primary btn-block mt-10 add_product_design"
              onClick={e => handleNewsSubmit(e)}
              type="button"
              disabled={isFetching}
            >
              {id ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};
NewsAddModal.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
NewsAddModal.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(NewsAddModal);
