import React, { useEffect } from "react";
import enhancer from "./enhancer/aboutusEnhancer";
import NavigationActions from "redux/navigation/actions";
import { useParams, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import { getAboutUs, updateAboutUs } from "services/settingservices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const Aboutus = props => {
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    setFieldValue,
    values,
    // handleChange,
    handleSubmit,
    setValues,
    isValid,
    // handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const { id } = useParams();
  // const [productImagesArr, setProductImagesArr] = useState([]);
  // const [artdata, setartdata] = useState([]);

  //USESTATE

  // const [categoryOptions, setCategoryOptions] = useState([]);

  const handleCategorySubmit = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      fetching();

      await updateAboutUs(token, values).then(data => {
        if (data.success) {
          success(data.message);
        } else {
          error(data.message);
        }
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const getAboutus = async () => {
    await getAboutUs(token).then(data => {
      if (data.success) {
        setValues({
          ...values,
          aboutus: data.data[0]?.aboutus
        });
        success();
        // setCategoryOptions(
        //   data.data.map((val) => ({ value: val.id, label: val.name }))
        // );
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getAboutus();
    // eslint-disable-next-line
  }, []);
  //USEEFFECTS

  return (
    <>
      <div className="">
        <div className="mx-3 mt-10">
          <div className="row mb-10 mt-10">
            <div className="col-12 mb-2">
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold"
                }}
              >
                About us
              </span>
            </div>
          </div>
        </div>

        {/* ADD PRODUCT */}
        <div className="card m-2 p-2">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                {/* <label >
                About us
                <span className="error-msg">*</span>
              </label> */}
                <div className="">
                  <ReactQuill
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() <= 1) {
                        setFieldValue("aboutus", "");
                      } else {
                        setFieldValue("aboutus", content);
                      }
                    }}
                    value={values.aboutus}
                    theme={"snow"}
                    placeholder={"Enter About us... "}
                    modules={Aboutus.modules}
                    formats={Aboutus.formats}
                    bounds={".app"}
                    id="aboutus"
                    name="aboutus"
                  />
                </div>
                <div>
                  <Error field="aboutus" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mt-10">
                <Button
                  className="btn c-primary px-5 add_product_design"
                  onClick={e => handleCategorySubmit(e)}
                  type="button"
                  disabled={isFetching}
                >
                  {/* {id ? "Update" : "Add"} */}
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Aboutus.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
Aboutus.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Aboutus);
